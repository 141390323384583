import apiClient from "./apiClient.js";

export default {
    async getAllMenuItems() {
        try {
            const response = await apiClient.get('/menu');
            return response.data;
        } catch (error) {
            console.error('Error fetching blog posts:', error);
            throw error;
        }
    }
}