<template>
  <nav class="bg-white p-4 shadow-sm rounded-md w-full mt-6 mb-4" aria-label="breadcrumb">
    <ol class="list-reset flex text-sm text-gray-700">
      <li v-for="(crumb, index) in breadcrumbs" :key="index" class="breadcrumb-item flex items-center">
        <router-link
            v-if="crumb.link"
            :to="crumb.link"
            class="text-green-500 hover:text-green-700 transition duration-300 ease-in-out"
        >
          {{ crumb.text }}
        </router-link>
        <router-link
            v-else-if="crumb.routeName"
            :to="{ name: crumb.routeName }"
            class="text-green-500 hover:text-green-700 transition duration-300 ease-in-out"
        >
          {{ crumb.text }}
        </router-link>
        <span v-else class="text-gray-500">{{ crumb.text }}</span>
        <span v-if="index < breadcrumbs.length - 1" class="mx-2 text-gray-400">/</span>
      </li>
    </ol>
  </nav>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const breadcrumbs = computed(() => store.getters['breadcrumbs/breadcrumbs']);
</script>


<style scoped>

</style>