import i18n from "../../i18n.js";

const state = {
    breadcrumbs: [],
};

const mutations = {
    SET_BREADCRUMBS(state, breadcrumbs) {
        state.breadcrumbs = breadcrumbs;
    },
};

const actions = {
    updateBreadcrumbs({ commit }, breadcrumbs) {
        const defaultBreadcrumbs = [
            { text: i18n.global.t('home'), routeName: 'home' },
        ];
        const updatedBreadcrumbs = defaultBreadcrumbs.concat(breadcrumbs);
        commit('SET_BREADCRUMBS', updatedBreadcrumbs);
    },
};

const getters = {
    breadcrumbs: (state) => state.breadcrumbs,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
