import apiClient from "./apiClient.js";

export default {
    async getPage(slug, language) {
        try {
            const response = await apiClient.get(`/page/${slug}`, {
                headers: {
                    'Content-Language': language,
                },
            });
            return response.data;
        } catch (error) {
            console.log('Error fetching page content', error)
            throw error;
        }
    }
}