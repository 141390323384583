import {createStore} from "vuex";
import blog from "./modules/blog.js";
import breadcrumbs from "./modules/breadcrumbs.js";
import testimonials from "./modules/testimonials.js";
import book from "./modules/book.js";
import language from "./modules/language.js";

export default createStore({
    modules: {
        language,
        breadcrumbs,
        blog,
        testimonials,
        book,
    },
})