import { createApp } from 'vue';
import './style.css';
import App from './App.vue';
import router from './router';
import store from "./store/index.js";
import { register } from 'swiper/element/bundle';
import ClickOutsideDirective from "./utils/ClickOutsideDirective.js";
import i18n from "./i18n.js"; // Import the i18n instance

// Register Swiper
register();

// Set the token from .env file into localStorage
const token = import.meta.env.VITE_BACKEND_API_KEY;
if (token) {
    localStorage.setItem('token', token);
}

// Create and mount the Vue app
createApp(App)
    .use(router)
    .use(store)
    .use(i18n) // Use the i18n instance
    .directive('click-outside', ClickOutsideDirective)
    .mount('#app');
