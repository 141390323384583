import apiClient from "../../services/apiClient.js";

const state = {
    testimonials: [],
    randomTestimonials: [],
    pagination: {
        current_page: 1,
        last_page: 1,
        per_page: 9,
        total: 0,
    },
};

const mutations = {
    SET_TESTIMONIALS(state, testimonials) {
        state.testimonials = testimonials;
    },
    SET_RANDOM_TESTIMONIALS(state, testimonials) {
        state.randomTestimonials = testimonials;
    },
    SET_PAGINATION(state, pagination) {
        state.pagination = pagination;
    },
};

const actions = {
    async fetchAllTestimonials({commit}, page = 1) {
        try {
            const response = await apiClient.get(`/testimonials?page=${page}`);
            commit('SET_TESTIMONIALS', response.data.data);
            commit('SET_PAGINATION', response.data.meta);
        } catch (error) {
            console.error('Error fetching testimonials:', error);
        }
    },
    async fetchRandomTestimonials({commit}) {
        try {
            const response = await apiClient.get('/testimonials/rand');
            commit('SET_RANDOM_TESTIMONIALS', response.data.data);
        } catch (error) {
            console.error('Error fetching random testimonials:', error);
        }
    },
};

const getters = {
    testimonials: (state) => state.testimonials,
    randomTestimonials: (state) => state.randomTestimonials,
    pagination: (state) => state.pagination,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
