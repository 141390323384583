<template>
  <div class="bg-emerald-50">
    <div class="container mx-auto py-16">
      <h1 class="text-4xl font-bold text-center">{{ page.name }}</h1>
    </div>
  </div>

  <div class="bg-white">
    <div class="container mx-auto bg-white">
      <Breadcrumb/>
    </div>
  </div>

  <section class="bg-white py-6">
    <div class="container mx-auto pb-24">
      <div class="lg:grid lg:grid-cols-4 lg:gap-x-12">
        <CategoriesAside/>

        <div class="col-span-3 order-2 lg:order-1">
          <div class="mx-auto grid grid-cols-1 gap-x-8 gap-y-20 lg:grid-cols-2 xl:grid-cols-3">
            <article v-for="post in blogArticles" :key="post.id" class="flex flex-col items-start justify-between">
              <router-link :to="{name: 'blog-single', params: {slug: post.slug}}">
                <div class="relative w-full shadow-lg rounded-2xl">
                  <img :src="post.thumb" alt="" class="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"/>
                  <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"/>
                </div>
              </router-link>
              <div class="mt-8 flex items-center gap-x-4 text-xs">
                <time :datetime="post.datetime" class="text-gray-500">{{ post.date }}</time>
              </div>
              <div class="group relative">
                <h3 class="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                  <router-link :to="{name: 'blog-single', params: {slug: post.slug}}">
                    <span class="absolute inset-0"/>
                    {{ post.name }}
                  </router-link>
                </h3>
                <p class="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{{ post.intro }}</p>
              </div>
            </article>
          </div>

          <Paginate :pagination="pagination" @page-changed="fetchArticlesByPage"/>

        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import {computed, watch, onMounted} from "vue";
import {useStore} from "vuex";
import Breadcrumb from "../../components/common/Breadcrumb.vue";
import CategoriesAside from "./CategoriesAside.vue";
import {useRoute, useRouter} from "vue-router";
import Paginate from "../../components/common/Paginate.vue";
import {usePage} from "../../composables/usePage.js";

const store = useStore();
const route = useRoute();
const router = useRouter();
const slug = computed(() => route.params.slug);

const blogArticles = computed(() => store.getters['blog/articles']);
const currentCategory = computed(() => store.getters['blog/currentCategory']);
const pagination = computed(() => store.getters['blog/pagination']);

const fetchArticles = async (page = 1) => {
  try {
    await store.dispatch('blog/fetchArticlesByCategory', {slug: slug.value, page, router});
    if (currentCategory.value) {
      await store.dispatch('breadcrumbs/updateBreadcrumbs', [
        {text: 'Blog', routeName: 'blog'},
        {text: currentCategory.value.name, link: null},
      ]);
    }
  } catch (error) {
    await router.push('/404');
  }
};

const fetchArticlesByPage = async (page) => {
  if (typeof page === 'object' && page.url) {
    const urlParams = new URLSearchParams(page.url.split('?')[1]);
    page = urlParams.get('page');
  }
  await fetchArticles(page);
};

const {page, fetchPage} = usePage('blog');

onMounted(async () => {
  await fetchArticles();
  await fetchPage();
});

watch(slug, fetchArticles);
</script>

<style scoped>

</style>