<template>
  <HeroSlider/>

  <section>
    <a href="/books">
    <div class="relative flex flex-row flex-wrap justify-center items-center gap-4 -top-12 shadow-xl z-10 bg-emerald-50 rounded-lg md:w-1/2 w-full mx-auto">
      <div class="flex-1 flex justify-center items-center">
        <img class="w-32 md:w-60" :src="press_logo" alt="Step to Bulgaria"/>
      </div>
      <div class="flex-1 flex justify-center items-center text-center">
        <h1 class="text-[13px] lg:text-xl uppercase inline-block">Към Издателството</h1>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5 md:size-9 inline-block mt-[-2px] ms-3">
          <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
        </svg>
      </div>
    </div>
    </a>
  </section>


  <div v-if="page.contentParsed">
    <div v-html="page.contentParsed[0]"></div>
    <TestimonialsSlider/>
    <div v-for="(content, index) in page.contentParsed.slice(1)" :key="index" v-html="content"></div>
  </div>
  <div v-else v-html="page.content"></div>

  <TestimonialsSlider/>
  <LatestFromBlog/>

</template>

<script setup>
import {computed, onMounted, watch} from 'vue';
import {usePage} from "../composables/usePage.js";
import {useStore} from 'vuex';
import TestimonialsSlider from "./Testimonials/TestimonialsSlider.vue";
import LatestFromBlog from "./Blog/LatestFromBlog.vue";
import HeroSlider from "../components/layout/HeroSlider.vue";

const store = useStore();
const {page, fetchPage} = usePage('home');
const press_logo = new URL('../assets/StepToBulgaria-logo_press.svg', import.meta.url);

const language = computed(() => store.getters['language/language']);

onMounted(fetchPage);

watch(language, () => {
  fetchPage();
});

</script>