import {createWebHistory, createRouter} from "vue-router";
import Home from "../views/Home.vue";
import NotFound from "../views/System/NotFound.vue";
import About from "../views/About.vue";
import Services from "../views/Services.vue";
import Testimonials from "../views/Testimonials/Testimonials.vue";
import BlogList from "../views/Blog/List.vue";
import BlogSingleView from "../views/Blog/SingleView.vue";
import BlogCategory from "../views/Blog/Category.vue";
import BookList from "../views/Book/List.vue";
import BookSingleView from "../views/Book/SingleView.vue";
import Rates from "../views/Rates.vue";
import Contacts from "../views/Contacts.vue";
import {setDocumentTitle} from "../utils/title.js";

const routes = [
    {
        path: "/",
        name: "home",
        component: Home,
        meta: {
            title: 'Home',
        }
    },
    {
        path: "/about",
        name: "about",
        component: About,
        meta: {
            title: 'About',
        }
    },
    {
        path: "/services",
        name: "services",
        component: Services,
        meta: {
            title: 'Services',
        }
    },
    {
        path: "/testimonials",
        name: "testimonials",
        component: Testimonials,
        meta: {
            title: 'Testimonials',
        }
    },
    {
        path: "/blog",
        name: "blog",
        component: BlogList,
        meta: {
            title: 'Blog'
        }
    },
    {
        path: "/blog/category/:slug",
        name: "blog-category",
        component: BlogCategory,
        meta: {
            title: 'Blog'
        }
    },
    {
        path: "/blog/:slug",
        name: "blog-single",
        component: BlogSingleView,
        meta: {
            title: 'Blog'
        }
    },
    {
        path: "/rates",
        name: "rates",
        component: Rates,
        meta: {
            title: 'Rates'
        }
    },
    {
        path: "/books",
        name: "books",
        component: BookList,
        meta: {
            title: 'Books'
        }
    },
    {
        path: "/books/:slug",
        name: "book-single",
        component: BookSingleView,
        meta: {
            title: 'Books'
        }
    },
    {
        path: "/contacts",
        name: "contacts",
        component: Contacts,
        meta: {
            title: 'Contacts'
        }
    },
    {
        path: "/:catchAll(.*)",
        name: "not-found",
        component: NotFound
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const defaultTitle = import.meta.env.VITE_APP_DEFAULT_TITLE ?? 'Default Title';
    const newTitle = to.meta?.title ? `${to.meta.title} - ${defaultTitle}` : defaultTitle;
    setDocumentTitle(newTitle)
    next();
});

export default router;