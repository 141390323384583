<template>
  <div class="bg-emerald-50">
    <div class="container mx-auto py-16">
      <img class="mx-auto" :src="press_logo" alt="Step to Bulgaria"/>
      <h1 class="text-2xl font-bold text-center uppercase">Издателство</h1>
      <div v-html="page.content" class="w-1/2 mx-auto py-10 text-lg"></div>
      <div class="flex space-x-10 justify-center items-center mb-14">
        <a href="https://www.facebook.com/knigitenalyubenstanev" target="_blank" class="bg-blue-50 cursor-pointer rounded-md shadow-md shadow-transparent transition-all duration-300 hover:shadow-emerald-500">
          <svg class="" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 92 92" fill="none">
            <rect x="0.138672" width="" height="" rx="15" fill="#EDF4FF"/>
            <path d="M56.4927 48.6403L57.7973 40.3588H49.7611V34.9759C49.7611 32.7114 50.883 30.4987 54.4706 30.4987H58.1756V23.4465C56.018 23.1028 53.8378 22.9168 51.6527 22.8901C45.0385 22.8901 40.7204 26.8626 40.7204 34.0442V40.3588H33.3887V48.6403H40.7204V68.671H49.7611V48.6403H56.4927Z" fill="#337FFF"/>
          </svg>
        </a>
      </div>
    </div>
  </div>

  <div class="bg-white">
    <div class="container mx-auto bg-white">
      <Breadcrumb/>
    </div>
  </div>

  <section class="bg-white py-6">
    <div class="container mx-auto pb-24">
      <div class="grid grid-cols-1 gap-x-8 gap-y-20 lg:grid-cols-2 xl:grid-cols-4">
        <article v-for="book in books" :key="book.id" class="flex flex-col items-center justify-between">
          <router-link :to="{ name: 'book-single', params: { slug: book.slug } }">
            <div class="relative w-full shadow-lg rounded-2xl">
              <img :src="book.cover" alt="" class="w-full rounded-2xl bg-gray-100 object-cover aspect-[3/4]"/>
              <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"/>
            </div>
          </router-link>
          <div class="mt-4 text-center flex flex-col items-center justify-start flex-grow">
            <h3 class="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
              <router-link :to="{ name: 'book-single', params: { slug: book.slug } }">
                <span class="inset-0">
                {{ book.title }}
                </span>
              </router-link>
            </h3>
          </div>
        </article>
      </div>

      <Paginate v-if="pagination" :pagination="pagination" @page-changed="fetchBooksByPage"/>
    </div>
  </section>
</template>

<script setup>
import Breadcrumb from "../../components/common/Breadcrumb.vue";
import {computed, onMounted} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import Paginate from "../../components/common/Paginate.vue";
import {usePage} from "../../composables/usePage.js";

const store = useStore();
const router = useRouter();
const books = computed(() => store.getters['book/books']);
const pagination = computed(() => store.getters['book/pagination']);
const press_logo = new URL('../../assets/StepToBulgaria-logo_press.svg', import.meta.url);

// Force the language to be 'bg'
const {page, fetchPage} = usePage('books', 'bg');

onMounted(fetchPage);

const fetchBooks = async (page = 1) => {
  try {
    await store.dispatch('book/fetchBooks', page);
    await store.dispatch('breadcrumbs/updateBreadcrumbs', [
      {text: 'Издателство', link: null}
    ]);
  } catch (error) {
    await router.push({name: 'not-found'});
  }
};

const fetchBooksByPage = async (page) => {
  if (typeof page === 'object' && page.url) {
    const urlParams = new URLSearchParams(page.url.split('?')[1]);
    page = urlParams.get('page');
  }

  await fetchBooks(page);
};

onMounted(fetchBooks);
</script>

<style scoped>
</style>
