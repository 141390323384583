// src/utils/meta.js
let previousMeta = {};

export function setMetaTags(newMeta) {
    // Store current meta tags
    previousMeta = {
        description: document.querySelector('meta[name="description"]')?.getAttribute('content'),
        keywords: document.querySelector('meta[name="keywords"]')?.getAttribute('content'),
    };

    // Set new meta tags
    if (newMeta.description) {
        let descriptionTag = document.querySelector('meta[name="description"]');
        if (!descriptionTag) {
            descriptionTag = document.createElement('meta');
            descriptionTag.name = 'description';
            document.head.appendChild(descriptionTag);
        }
        descriptionTag.setAttribute('content', newMeta.description);
    }

    if (newMeta.keywords) {
        let keywordsTag = document.querySelector('meta[name="keywords"]');
        if (!keywordsTag) {
            keywordsTag = document.createElement('meta');
            keywordsTag.name = 'keywords';
            document.head.appendChild(keywordsTag);
        }
        keywordsTag.setAttribute('content', newMeta.keywords);
    }
}

export function restorePreviousMetaTags() {
    // Restore previous meta tags
    if (previousMeta.description) {
        document.querySelector('meta[name="description"]')?.setAttribute('content', previousMeta.description);
    }
    if (previousMeta.keywords) {
        document.querySelector('meta[name="keywords"]')?.setAttribute('content', previousMeta.keywords);
    }
}
