import apiClient from "../../services/apiClient.js";

const state = {
    books: [],
    book: null,
    articles: [],
    article: null,
    reviews: [],
    pagination: {
        current_page: 1,
        last_page: 1,
        per_page: 9,
        total: 0,
    },
};

const mutations = {
    SET_BOOKS(state, books) {
        state.books = books;
    },
    SET_BOOK(state, book) {
        state.book = book;
    },
    SET_ARTICLES(state, articles) {
        state.articles = articles;
    },
    SET_ARTICLE(state, article) {
        state.article = article;
    },
    SET_REVIEWS(state, reviews) {
        state.reviews = reviews;
    },
    SET_PAGINATION(state, pagination) {
        state.pagination = pagination;
    },
};

const actions = {
    async fetchBooks({ commit }, page = 1) {
        try {
            const response = await apiClient.get(`/book?page=${page}`, {
                headers: {
                    'Content-Language': 'bg',
                },
            });
            commit('SET_BOOKS', response.data.data);
            commit('SET_PAGINATION', response.data.meta);
        } catch (error) {
            console.error('Error fetching books:', error);
        }
    },
    async fetchBook({ commit }, slug) {
        try {
            const response = await apiClient.get(`/book/${slug}`, {
                headers: {
                    'Content-Language': 'bg',
                },
            });
            commit('SET_BOOK', response.data.data);
        } catch (error) {
            console.error('Error fetching book:', error);
        }
    },
    async fetchBookArticles({ commit }, { slug, page = 1 }) {
        try {
            const response = await apiClient.get(`/book/${slug}/articles?page=${page}`, {
                headers: {
                    'Content-Language': 'bg',
                },
            });
            commit('SET_ARTICLES', response.data.data);
            commit('SET_PAGINATION', response.data.meta);
        } catch (error) {
            console.error('Error fetching book articles:', error);
        }
    },
    async fetchBookArticle({ commit }, { slug, articleId }) {
        try {
            const response = await apiClient.get(`/book/${slug}/article/${articleId}`, {
                headers: {
                    'Content-Language': 'bg',
                },
            });
            commit('SET_ARTICLE', response.data.data);
        } catch (error) {
            console.error('Error fetching book article:', error);
        }
    },
    async fetchBookReviews({ commit }, { slug, page = 1 }) {
        try {
            const response = await apiClient.get(`/book/${slug}/reviews?page=${page}`, {
                headers: {
                    'Content-Language': 'bg',
                },
            });
            commit('SET_REVIEWS', response.data.data);
            commit('SET_PAGINATION', response.data.meta);
        } catch (error) {
            console.error('Error fetching book reviews:', error);
        }
    },
};

const getters = {
    books: (state) => state.books,
    book: (state) => state.book,
    articles: (state) => state.articles,
    article: (state) => state.article,
    reviews: (state) => state.reviews,
    pagination: (state) => state.pagination,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};