let previousTitle = '';

export function setDocumentTitle(newTitle) {
    previousTitle = document.title;
    document.title = newTitle;
}

export function restorePreviousTitle() {
    if (previousTitle) {
        document.title = previousTitle;
    }
}