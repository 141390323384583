import apiClient from "../../services/apiClient.js";

const state = {
    categories: [],
    articles: [],
    article: null,
    currentCategory: null,
    pagination: {
        current_page: 1,
        last_page: 1,
        per_page: 9,
        total: 0,
    },
    latestArticles: [],
};

const mutations = {
    SET_CATEGORIES(state, categories) {
        state.categories = categories;
    },
    SET_ARTICLES(state, articles) {
        state.articles = articles;
    },
    SET_ARTICLE(state, article) {
        state.article = article;
    },
    SET_CURRENT_CATEGORY(state, category) {
        state.currentCategory = category;
    },
    SET_PAGINATION(state, pagination) {
        state.pagination = pagination;
    },
    SET_LATEST_ARTICLES(state, articles) { // Add this mutation
        state.latestArticles = articles;
    },
};

const actions = {
    async fetchCategories({commit}) {
        try {
            const response = await apiClient.get('/blog-category');
            commit('SET_CATEGORIES', response.data.data);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    },
    async fetchArticles({commit}, page = 1) {
        try {
            const response = await apiClient.get(`/blog?page=${page}`);
            commit('SET_ARTICLES', response.data.data);
            commit('SET_PAGINATION', response.data.meta);
        } catch (error) {
            console.error('Error fetching articles:', error);
        }
    },
    async fetchArticleBySlug({commit}, {slug, router}) {
        try {
            const response = await apiClient.get(`/blog/${slug}`);
            commit('SET_ARTICLE', response.data.data);
        } catch (error) {
            router.push('/404');
        }
    },
    async fetchArticlesByCategory({commit}, {slug, page = 1, router}) {
        try {
            const response = await apiClient.get(`/blog-category/${slug}?page=${page}`);
            commit('SET_ARTICLES', response.data.articles);
            commit('SET_CURRENT_CATEGORY', response.data.category);
            commit('SET_PAGINATION', response.data.meta);
            return true;
        } catch (error) {
            router.push('/404');
        }
    },
    async fetchLatestArticles({commit}) { // Add this action
        try {
            const response = await apiClient.get('/blog/latest');
            commit('SET_LATEST_ARTICLES', response.data.data);
        } catch (error) {
            console.error('Error fetching latest articles:', error);
        }
    },
};

const getters = {
    categories: (state) => state.categories,
    articles: (state) => state.articles,
    article: (state) => state.article,
    currentCategory: (state) => state.currentCategory,
    pagination: (state) => state.pagination,
    latestArticles: (state) => state.latestArticles,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
