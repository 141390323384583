<template>
  <div class="bg-emerald-50">
    <div class="container mx-auto py-16">
      <h1 class="text-4xl font-bold text-center">{{ page.name }}</h1>
    </div>
  </div>

  <section class="bg-white py-6">
    <div v-for="(testimonial, index) in testimonials" :key="testimonial.id" :class="getTestimonialClass(index)">
      <figure class="max-w-screen-md mx-auto px-4 py-6">
        <svg class="h-12 mx-auto mb-3 text-emerald-400" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor"/>
        </svg>
        <blockquote>
          <p class="text-xl lg:text-2xl max-lg:px-12 font-medium">"{{ testimonial.testimonial }}"</p>
        </blockquote>
        <figcaption class="flex items-center justify-center mt-6 space-x-3">
          <div class="flex items-center divide-x-2 divide-gray-500">
            <div class="pr-3 font-medium">{{ testimonial.name }}</div>
            <div class="pl-3 text-sm font-light text-gray-500">{{ testimonial.position }}</div>
          </div>
        </figcaption>
      </figure>
    </div>
    <Paginate :pagination="pagination" @page-changed="fetchTestimonialsByPage" class="pb-16"/>
  </section>
</template>

<script setup>
import {useStore} from 'vuex';
import {onMounted, computed} from 'vue';
import {usePage} from "../../composables/usePage.js";
import Paginate from "../../components/common/Paginate.vue"; // Adjust the path as necessary

const store = useStore();
const testimonials = computed(() => store.getters['testimonials/testimonials']);
const pagination = computed(() => store.getters['testimonials/pagination']);

const fetchTestimonialsByPage = (page) => {
  store.dispatch('testimonials/fetchAllTestimonials', page);
};

const getTestimonialClass = (index) => {
  return index % 2 !== 0 ? 'bg-emerald-50 py-12' : 'bg-white py-12';
};

const {page, fetchPage} = usePage('testimonials');

onMounted(async () => {
  await store.dispatch('testimonials/fetchAllTestimonials');
  await fetchPage();
});
</script>