import { ref, watch } from 'vue';
import { setDocumentTitle, restorePreviousTitle } from '../utils/title.js';
import { setMetaTags, restorePreviousMetaTags } from "../utils/meta.js";
import pageService from '../services/pageService.js';
import { useStore } from 'vuex';

export function usePage(slug, language) {
    const page = ref({});
    const store = useStore();
    const currentLanguage = ref(language || store.getters['language/currentLanguage']);
    const siteTitle = import.meta.env.VITE_APP_DEFAULT_TITLE;

    async function fetchPage() {
        try {
            const response = await pageService.getPage(slug, currentLanguage.value);
            page.value = response.data;
        } catch (error) {
            console.error("Error fetching page:", error);
        }
    }

    // Watch the page object and update the document title when it changes
    watch(page, (newPage) => {
        if (newPage?.name) {
            setDocumentTitle(newPage.name + " :: " + siteTitle);
            setMetaTags({
                description: newPage.seoDescription || ''
            });
        } else {
            restorePreviousTitle();
            restorePreviousMetaTags();
        }
    }, { immediate: true });

    // Watch for language changes and refetch the page
    watch(currentLanguage, fetchPage);

    return { page, fetchPage };
}