<template>
  <swiper
      :autoplay="{
            delay: 5000,
            disableOnInteraction: false,
          }"
  >
    <swiper-slide class="relative">
      <div class="hero-container">
        <img :src="bg" class="w-full h-[20rem] md:h-[30rem] lg:h-[40rem] object-cover" alt="Step to Bulgaria"/>
        <div class="hero-static flex flex-col items-center text-center px-4 lg:px-40 bg-black bg-opacity-50 p-4 rounded-md">
          <h1 class="animate-fadeInRight text-white font-bold mb-2 px-10">
            Bulgarian language & culture through involvement & hands-on activities!
          </h1>
          <h1 class="opacity-0 animate-fadeInRightDelayed text-white mt-4">
            Get your first language & culture class for free!
          </h1>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import 'swiper/css';
const bg = new URL('../../assets/bg/bg.png', import.meta.url);
</script>

<style scoped>
</style>
