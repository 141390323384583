<template>
  <nav aria-label="Pagination" class="mt-16 flex justify-center">
    <ul class="inline-flex items-center -space-x-px">
      <li v-if="pagination.prev">
        <button
            @click="changePage(pagination.current_page - 1)"
            class="px-3 py-2 ml-0 leading-tight bg-white border border-gray-300 hover:bg-emerald-400 hover:text-white"
            v-html="'&laquo; Previous'"
        ></button>
      </li>
      <li v-for="link in pagination.links" :key="link.label" :class="{'bg-emerald-400 text-white': link.active}">
        <button v-if="link.url" @click="changePage(link)" :class="linkClasses(link)" v-html="link.label"></button>
        <span v-else :class="spanClasses(link)" v-html="link.label"></span>
      </li>
      <li v-if="pagination.next">
        <button
            @click="changePage(pagination.current_page + 1)"
            class="px-3 py-2 leading-tight bg-white border border-gray-300 rounded-r-2xl hover:bg-emerald-400 hover:text-white"
            v-html="'Next &raquo;'"
        ></button>
      </li>
    </ul>
  </nav>
</template>

<script setup>
const props = defineProps({
  pagination: {
    type: Object,
    required: true,
  }
});

const emits = defineEmits(['page-changed']);

const changePage = (page) => {
  if (typeof page === 'object' && page.url) {
    const urlParams = new URLSearchParams(page.url.split('?')[1]);
    page = urlParams.get('page');
  }
  emits('page-changed', page);
};

const linkClasses = (link) => {
  let classes = 'px-3 py-2 leading-tight border border-gray-300 hover:bg-emerald-400 hover:text-white';
  if (link.active) {
    classes += ' bg-emerald-400 text-white';
  }
  if (link.label === '&laquo; Previous') {
    classes += ' rounded-l-2xl';
  }
  if (link.label === 'Next &raquo;' || (props.pagination.meta && link.label === props.pagination.meta.last_page.toString())) {
    classes += ' rounded-r-2xl';
  }

  return classes;
};

const spanClasses = (link) => {
  let classes = 'px-3 py-2 leading-tight bg-white border border-gray-300';
  if (link.label === '1') {
    classes += ' rounded-l-2xl';
  }
  if (link.label === '&laquo; Previous') {
    classes += ' rounded-l-2xl';
  }
  if (link.label === 'Next &raquo;' || (props.pagination.meta && link.label === props.pagination.meta.last_page.toString())) {
    classes += ' rounded-r-2xl';
  }
  return classes;
};
</script>
