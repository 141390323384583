import axios from "axios";
import store from '../store'; // Adjust the path according to your project structure

const baseUrl = import.meta.env.VITE_API_BASE_URL;

const apiClient = axios.create({
    baseURL: baseUrl,
    headers: {
        "Content-Type": "application/json",
    }
});

apiClient.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    const language = config.headers['Content-Language'] || store.getters['language/currentLanguage'];  // Get current language from Vuex store or override if provided

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    if (language) {
        config.headers['Content-Language'] = language;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

apiClient.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 404) {
            console.clear();
            return Promise.resolve({status: 404, data: null});
        }
        return Promise.reject(error);
    }
);

export default apiClient;