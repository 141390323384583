<template>
  <div class="bg-emerald-50">
    <div class="container mx-auto py-16">
      <h1 class="text-4xl font-bold text-center">{{ page.name }}</h1>
    </div>
  </div>

  <div class="bg-white">
    <div class="container mx-auto bg-white">
      <Breadcrumb/>
    </div>
  </div>

  <section class="bg-white py-6">
    <div class="container mx-auto pb-24">
      <div class="lg:grid lg:grid-cols-4 lg:gap-x-12">
        <!-- Article Content -->
        <div class="col-span-3 order-1 lg:order-1">
          <div v-if="article" class="bg-white shadow-md rounded-lg p-8">
            <!-- Article Cover Image -->
            <div class="mb-8">
              <img :src="article.cover" alt="Cover Image" class="w-full h-auto rounded-lg object-cover">
            </div>

            <!-- Article Title -->
            <h1 class="text-4xl font-bold mb-6 text-gray-800">{{ article.name }}</h1>

            <!-- Article Content -->
            <div class="prose max-w-none mb-6" v-html="article.content"></div>

            <!-- Article Metadata -->
            <div class="text-gray-600 text-sm">
              <p><strong>Category:</strong> {{ article.category.name }}</p>
              <p><strong>Date:</strong> {{ article.date }}</p>
            </div>
          </div>
        </div>

        <!-- Categories Aside -->
        <aside class="col-span-1 order-2 hidden lg:block">
          <CategoriesAside />
        </aside>
      </div>
    </div>
  </section>


</template>

<script setup>
import {ref, computed, onMounted, watch} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {useStore} from 'vuex';
import CategoriesAside from "./CategoriesAside.vue";
import Breadcrumb from "../../components/common/Breadcrumb.vue";
import {usePage} from "../../composables/usePage.js";

const store = useStore();
const route = useRoute();
const router = useRouter();
const slug = computed(() => route.params.slug);

const article = ref(null);

const fetchArticleBySlug = async () => {
  try {
    await store.dispatch('blog/fetchArticleBySlug', {slug: slug.value, router});
    const fetchedArticle = store.getters['blog/article'];
    article.value = fetchedArticle;
    if (article.value) {
      await store.dispatch('breadcrumbs/updateBreadcrumbs', [
        {text: 'Blog', routeName: 'blog'},
        {text: fetchedArticle.name, link: null},
      ]);
    }
  } catch (error) {
    await router.push('/404');
  }
};

const {page, fetchPage} = usePage('blog');

onMounted(async () => {
  await fetchArticleBySlug();
  await fetchPage();
});

watch(slug, fetchArticleBySlug);

</script>